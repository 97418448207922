<template>
    <div class="content-wrapper">
        <div class="img-content">
            <img :src="require(`../images/${entry.filename}`)" alt="full size image">
        </div>
        <div class="detail-wrapper">
            <h1>{{ entry.title }}</h1>
            <h2>{{ entry.author }}</h2>
            <p>Dimensions: {{ entry.dimensions }} </p>
            <div class="tag-wrapper">
                <button class="tag">encounter</button>  <!-- example tags -->
                <button class="tag">overhead</button>
                <button class="tag">mountain</button>
            </div>
            <va-button class="btn-download">Download</va-button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Gallery Entry',
    props: {
        entry: {
            type: Object,
            default: () => ({
                title: "A Very Cool Map",
                author: "Calvin Cartographer",
                filename: "mountain-arena.jpg",
                dimensions: "23x34",
            })
        }
    }
}
</script>

<style scoped>
.content-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.img-content {
    margin: 10px 0 20px 0;
}
    .img-content > img {
        height: 80vh;
    }
.detail-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    background-color: #fff;
    border-radius: 8px;
    padding: 5px;
    width: 375px;
    margin-bottom: 20px;
}
    .detail-wrapper > h1 {
        font-size: 32px;
        margin-top: 10px;
    }
    .detail-wrapper > h2 {
        font-size: 20px;
        margin-bottom: 5px;
    }
    .tag-wrapper {
        margin-top: 5px;
        display: flex;
        gap: 5px;
    }
    .tag {
        border: none;
        background: #e5e5e5;
        border-radius: 46px;
        gap: 8px;
        padding: 6px;
    }

.btn-download {
    margin: 10px 0 10px 0;
}
</style> 